<style>
    .demo-tabs-style1 > .ivu-tabs-card > .ivu-tabs-content {
        height: 120px;
        margin-top: -16px;
    }

    .demo-tabs-style1 > .ivu-tabs-card > .ivu-tabs-content > .ivu-tabs-tabpane {
        background: #fff;
        padding: 16px;
    }

    .demo-tabs-style1 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab {
        border-color: transparent;
    }

    .demo-tabs-style1 > .ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab-active {
        border-color: #fff;
    }
    .demo-tabs-style2 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab{
        border-radius: 0;
        background: #fff;
    }
    .demo-tabs-style2 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab-active{
        border-top: 1px solid #3399ff;
    }
    .demo-tabs-style2 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab-active:before{
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: #3399ff;
        position: absolute;
        top: 0;
        left: 0;
    }

    .entrybtn {
        width:100px;
        height:80px;
        margin:0px 2px;
        font-weight: bold;
        color: rgb(64, 103, 209);
        box-shadow: 0 0 20px 2px rgb(100 0 0 / 40%);
        background: #97aedd;
        border: 1px solid rosybrown;
        line-height: 80px;
        text-align: center;
        cursor: pointer;
    }

    .entrybtnactive {
        width:100px;
        height:80px;
        margin:0px 2px;
        font-weight: bold;
        color: rgb(64, 103, 209);
        box-shadow: 0 0 20px 2px rgb(100 0 0 / 40%);
        background: #d8d6d6;
        border: 1px solid rosybrown;
        line-height: 80px;
        text-align: center;
        cursor: pointer;
    }

    .entrybtnhover {
        width:100px;
        height:80px;
        margin:0px 2px;
        font-weight: bold;
        color: rgb(64, 103, 209);
        box-shadow: 0 0 20px 2px rgb(100 0 0 / 40%);
        background: #f8f8e0;
        border: 1px solid rosybrown;
        line-height: 80px;
        text-align: center;
        cursor: pointer;
    }

    .entrybtn:hover{
        background: #d8d6d6;
    }

    .selectedspan{
        color: rgb(152, 8, 248);
        margin-left: 2px;
        font-weight: bold;
        font-size: large;
    }
</style>
<template>
    <div style="background: #ffffff;height:100%;margin:auto;margin-top:0px;padding:0px;">
        <loginfobar style="width:90%;margin:auto;margin-top:0px;padding:0px 40px 0px"/>
        <div style="margin:auto;width:90%;background:#cbd4f4;padding-bottom:15px">
            <div style="padding:5px 0px;float: left;display:inline-flex">
                <div :class="getbtnclass('welcome')"  @mouseover="mouseOver($event)" @mouseleave="mouseLeave($event)" @click="onentryclick('welcome')">主页</div>
                <div v-hasPermission="'model:entry'" :class="getbtnclass('technology')"  @mouseover="mouseOver($event)" @mouseleave="mouseLeave($event)" @click="onentryclick('technology')">技术模块</div>
                <div v-hasPermission="'workshop:entry'" :class="getbtnclass('workshop')" @mouseover="mouseOver($event)" @mouseleave="mouseLeave($event)" @click="onentryclick('workshop')">生产模块</div>
                <div v-hasPermission="'sale:entry'" :class="getbtnclass('Sell')"  @mouseover="mouseOver($event)" @mouseleave="mouseLeave($event)" @click="onentryclick('Sell')">销售模块</div>
                <div v-hasPermission="'purchase:entry'" :class="getbtnclass('purchase')" @mouseover="mouseOver($event)" @mouseleave="mouseLeave($event)" @click="onentryclick('purchase')">采购模块</div>
                <div v-hasPermission="'finacial:entry'" :class="getbtnclass('Finance')" @mouseover="mouseOver($event)" @mouseleave="mouseLeave($event)" @click="onentryclick('Finance')">财务模块</div>
                <div v-hasPermission="'plan:entry'" :class="getbtnclass('Plan')" @mouseover="mouseOver($event)" @mouseleave="mouseLeave($event)" @click="onentryclick('Plan')">计划模块</div>
                <div v-hasPermission="'productwarehouse:entry'" :class="getbtnclass('warehouse')" @mouseover="mouseOver($event)" @mouseleave="mouseLeave($event)" @click="onentryclick('warehouse')">成品库</div>
                <div v-hasPermission="'partwarehouse:entry'" :class="getbtnclass('parthouse')" @mouseover="mouseOver($event)" @mouseleave="mouseLeave($event)" @click="onentryclick('parthouse')">零件库</div>
                <div :class="getbtnclass('welcome2')"  @mouseover="mouseOver($event)" @mouseleave="mouseLeave($event)"  @click="onentryclick('welcome')">售后</div>
                <div :class="getbtnclass('welcome3')"  @mouseover="mouseOver($event)" @mouseleave="mouseLeave($event)"  @click="onentryclick('welcome')">质检</div>
                <div :class="getbtnclass('welcome4')"  @mouseover="mouseOver($event)" @mouseleave="mouseLeave($event)"  @click="onentryclick('welcome')">研发</div>
                <div :class="getbtnclass('welcome5')"  @mouseover="mouseOver($event)" @mouseleave="mouseLeave($event)"  @click="onentryclick('welcome')">认证管理</div>
            </div>
            <div style="clear: both;">
                <router-view />
            </div>
            <div style="margin-top:18px;clear: both;">
                <a style="font-size:10px"  href="http://ddc.2kj.cn/Lodop6.226_Clodop3.093.zip">打印插件下载</a>
            </div>
        </div>
    </div>
</template>

<script>
import UserInfoTitleBar from '@/views/common/UserInfoTitleBar.vue'
export default {
    name:'WorkerHome',
    data(){
        return {
            activeentry:'',
            companycode: this._self.$root.$store.state.LogonInfo.companycode,
            departmap:[
                {id:101,departname:'生产部门',entry:'workshop/'},
                {id:102,departname:'成品部门',entry:'warehouse/'},
                {id:103,departname:'技术部门',entry:'technology/'},
                {id:104,departname:'财务部门',entry:'Finance/'},
                {id:105,departname:'计划部门',entry:'Plan/'},
                {id:106,departname:'采购部门',entry:'purchase/'},
                {id:107,departname:'销售部门',entry:'Sell/'}, 
                {id:108,departname:'零件部门',entry:'parthouse/'},
                {id:150,departname:'认证部门',entry:'checkdept/'}, 
            ]
        }
    },
    components:{
        loginfobar:UserInfoTitleBar,
    },
    computed:{
    },
    watch:{
        $route: {
            handler: function(val, oldVal){
                console.log(val)
                if(val !=null){
                    this.activeentry = this.getactivemodel()
                }
            },
        }
    },
    mounted(){
        this.activeentry = this.getactivemodel()
    },
    methods:{
        getactivemodel(){
            var str = this.$route.path.split("/");
            return str[str.length -1]
        },
        getbtnclass: function(modelname){
            let classname = this.activeentry == modelname ? 'entrybtnactive' : 'entrybtn'
            return classname
        },
        onentryclick(modelname){
            this.activeentry = modelname
            //console.log(this.$route)
            let topath = "/business/workhome/"+this.companycode+"/"+modelname
            this.$router.push({ path: topath })
        },
        mouseOver($event){
            if($event.currentTarget.className != 'entrybtnactive'){
                $event.currentTarget.className = "entrybtnhover";
            }
        },
        mouseLeave($event) {
            if($event.currentTarget.className != 'entrybtnactive'){
                $event.currentTarget.className = "entrybtn";
            }
        },
    },
}
</script>